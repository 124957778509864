export default class EventManager
{

    public static eventItems:Array<EventItem>;

    public static init():void
    {
        this.eventItems = new Array<EventItem>();
    }

    public static on( cel:any , event:String , func:Function )
    {
        let e:EventItem = new EventItem();
        e.event = event;
        e.func = func;
        e.cel = cel;
        new Array();
        this.eventItems.push(e);
    }

    public static dispatch(event:String , data:any)
    {
        for(let i = 0 ; i < this.eventItems.length ; i ++)
        {
            if(this.eventItems[i].event == event)
            {
                this.eventItems[i].func.call(this.eventItems[i].cel , data);
            }
        }
    }

    public static remove(cel:any , event:String)
    {
        for(let i = 0 ; i < this.eventItems.length ; i ++)
            {
                if(this.eventItems[i].event == event && this.eventItems[i].cel == cel)
                {
                    this.eventItems.splice(i , 1);
                }
            }
    }


}
class EventItem
{
    public event:String;
    public func:Function;
    public cel:any;
}