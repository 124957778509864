import { HttpItem } from "./HttpItem";

const { regClass } = Laya;
export class HttpManager
{
    private static url:string = "http://127.0.0.1:8099/";
    private static httpList:Array<HttpItem>;
    private static isBusy:boolean;

    public static init()
    {
        HttpManager.httpList = [];
        HttpManager.isBusy = false;
    }
    public static sendQueue(method:string , data:any , owner:any, completeFunc:any , custonData:any):void
    {
        let item: HttpItem= new HttpItem(this.url , method , data ,owner , completeFunc , custonData , true);
        HttpManager.httpList.push(item);
        HttpManager.sendHttp();
    }

    public static httpCom()
    {
        if(HttpManager.httpList.length > 0)
        {
            HttpManager.httpList.shift();
        }
        HttpManager.isBusy = false;
        HttpManager.sendHttp();

    }

    private static sendHttp():void
    {
        if(!HttpManager.isBusy && HttpManager.httpList.length > 0)
        {
            HttpManager.isBusy = true;
            HttpManager.httpList[0].send();
        }
    }

    public static sendalone(method:string , data:any ,owner:any, completeFunc:any , custonData:any):void
    {
        let item: HttpItem= new HttpItem(this.url , method , data , owner , completeFunc , custonData , false);
        item.send();
    }


}