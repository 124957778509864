import DictClass from "../data/DictClass";
import EventManager from "../tools/event/EventManager";

const { regClass ,property} = Laya;
@regClass('d786GKHHTomFcGs6qz6NXA')
export default class ClassItem extends Laya.Script
{
    @property({type:"Button"})
    public item:Laya.Button;

    public dictClass:DictClass;

    onAwake(): void {
        
    }
    onStart(): void {
       this.owner.on(Laya.Event.CLICK , this , this._click); 
       EventManager.on(this , "classClick" , this._clickFunc);
    }

    public setData(data:any)
    {
        this.dictClass = data;
        this.item.label = data.name;
        if(this.dictClass.id == -1)
        {
            this._click(null);
        }
    }
    _click(e:any)
    {
        EventManager.dispatch("classClick" , this.dictClass);
        this.item.disabled = true;
    }
    _clickFunc(e:any)
    {
        this.item.disabled = false;
    }

}