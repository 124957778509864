import VideoItem from "../../data/VideoItem";
import DataManager from "../../DataManager";
// import { ExternalInterface } from 'laya/utils/ExternalInterface';
const { regClass ,property} = Laya;
@regClass('0xp2fZcJRxaLqtQNTxsKMg')
export default class VideoItemCom extends Laya.Script
{
    @property({type:"Text"})
    public itemText:Laya.Text;
    private data:VideoItem;
    onStart(): void {
        this.itemText.on(Laya.Event.CLICK,this , this._onClick);
    }

    public setData(data:any)
    {
        this.data = data;
        this.itemText.text = this.data.title;
    }


    _onClick()
    {
        
        console.log(Laya.Browser.window);
        Laya.Browser.window.videoPlay(DataManager.cndHost + this.data.path+"index.m3u8");

    }
}