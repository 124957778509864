import VideoItem from "../data/VideoItem";
import VideoSet from "../data/VideoSet";
import DataManager from "../DataManager";
import { PageManager } from "../tools/PageManager";

const { regClass ,property} = Laya;
@regClass('ViSeYs-pSPKgV1Iybhe8RQ')
export default class VideoSetItem extends Laya.Script
{
    @property({type:"Image"})
    public coverImg:Laya.Image;

    @property({type:"Text"})
    public nameTxt:Laya.Text;

    private videoSetItem:VideoSet;


    public setData(data:any)
    {
        this.videoSetItem = data;

        Laya.loader.load(DataManager.cndHost + this.videoSetItem.cover, Laya.Loader.IMAGE).then((value:any)=>{
            this.coverImg.source =value;
        });
        this.nameTxt.text = this.videoSetItem.title;
        this.owner.on(Laya.Event.CLICK ,this, this._onClick);


       
    }
    _onClick(e:any)
    {
        PageManager.showWindow(PageManager.inst.videoItemListPage , this.videoSetItem);
    }
}