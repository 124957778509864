import { WindowBase } from "./WindowBase";

const { regClass ,property} = Laya;
@regClass('1RfTC0caTaKQqnAlC-M5kg')
export class PageManager extends Laya.Script
{
    @property({type:"Sprite"})
    public windowLaya:Laya.Sprite;
    @property({type:"Prefab"})
    public videoItemListPage:Laya.Prefab;



    public static inst:PageManager;

    onAwake(): void {
        PageManager.inst = this;
    }

    public static showWindow(winClass:any , data:any)
    {
       // Laya.loader('prefab/testPrefab.json',Laya.Handler.create(this,this.onPrefabLoader));
        

        let win =  winClass.create();
        win.data = data;

        PageManager.inst.windowLaya.addChild(win);
        win.getComponent(WindowBase).initData(data);
        
        // win.x = (1920-win.width)/2;
        // win.y = (1080 - win.height)/2;
        win.x = 0;
        win.y = 0;
    }

    public static closeWindow(win:any)
    {
        // win.destroy();
        win.removeSelf();
        win.getComponent(WindowBase).destroySelf();
       

         
    }


}