export default class ADManager
{
  
  // Display Google Ads after script load
   static displayGoogleAd() {
    let adContainer = document.getElementById('ad-container');
  
    if (adContainer) {
      adContainer.innerHTML = `
        <ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-4059460294649965"
     data-ad-slot="1639766837"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
      `;
      // Request ad rendering
      (window as any).adsbygoogle = (window as any).adsbygoogle || [];
      (window as any).adsbygoogle.push({});
    }
  }
  

  public static showAD()
  {
    ADManager.displayGoogleAd();
  }

  public static removeAD()
  {
    let adContainer = document.getElementById('ad-container');
    adContainer.innerHTML = "";
  }



}