import Config from "./data/Config";
import DictClass from "./data/DictClass";
import VideoSet from "./data/VideoSet";
import EventManager from "./tools/event/EventManager";

export default class DataManager
{

    // public static ossHost:string = "https://s3.ap-northeast-1.wasabisys.com/321video/";

    public static  cndHost:string = "https://s3.ap-northeast-1.wasabisys.com/321video/";
    // public static  cndHost:string = "https://s3.ap-northeast-1.wasabisys.com/321video/";
    public static config:Config;
    public static dictClass:Array<DictClass>;
    public static videoSet:Array<VideoSet>;

    public static updateData()
    {



        Laya.loader.load(DataManager.cndHost + "config/globleconfig.txt?v=" + Math.random(), Laya.Handler.create(this, (d:any)=>{
            console.log(d.data); // 这里是URL的内容
            DataManager.config = JSON.parse(d.data);


            Laya.loader.load(DataManager.cndHost +  DataManager.config.dictClassPath, Laya.Handler.create(this, (dd:any)=>{
                console.log(dd.data); // 这里是URL的内容
                DataManager.dictClass = JSON.parse(dd.data);
                DataManager.dictClass = DataManager.dictClass.sort((a , b)=> a.index-b.index);
                

                Laya.loader.load(DataManager.cndHost + DataManager.config.videoSetDataPath, Laya.Handler.create(this, (ddd:any)=>{
                    console.log(ddd.data); // 这里是URL的内容
                   
                    
                    DataManager.videoSet = JSON.parse(ddd.data);
                    DataManager.videoSet = DataManager.videoSet.sort((a , b)=> b.lastUpdateTime-a.lastUpdateTime);
                    EventManager.dispatch("configDataUpdate" , null);
        
                }), null, Laya.Loader.TEXT);
            }), null, Laya.Loader.TEXT);
        }), null, Laya.Loader.TEXT);


        // DataManager. fetchUrlContent(DataManager.cndHost + "config/globleconfig.txt?v=" + new Date().getUTCMilliseconds()).then(content => {
        //     console.log(content); // 这里是URL的内容
        //     DataManager.config = JSON.parse(content);

        //     DataManager. fetchUrlContent(DataManager.cndHost +  DataManager.config.dictClassPath).then(content => {
        //         console.log(content); // 这里是URL的内容
        //         DataManager.dictClass = JSON.parse(content);
        //         DataManager.dictClass = DataManager.dictClass.sort((a , b)=> a.index-b.index);
        //         DataManager. fetchUrlContent(DataManager.cndHost + DataManager.config.videoSetDataPath).then(content => {
        //             console.log(content); // 这里是URL的内容
        //             DataManager.videoSet = JSON.parse(content);
        //             DataManager.videoSet = DataManager.videoSet.sort((a , b)=> a.lastUpdateTime.getMilliseconds()-b.lastUpdateTime.getMilliseconds());
        //             EventManager.dispatch("configDataUpdate" , null);
                    
        //         });
        //     });
        // });
    }



    public static async fetchUrlContent(url: string): Promise<string>{
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return await response.text();
        } catch (error) {
            console.error(error);
            return '';
        }
    }

    


     
   
   
    
}