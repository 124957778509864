import { HttpManager } from "./HttpManager";

export class HttpItem
{
    public _completeHandler:Function;



    public _processHandler:Function;

    public data:any;

    public url:string;

    private method:string;

    public customData:any;
    private isQueue:boolean;

    private owner:any;

    
    constructor(url:string , method:string , data:any , owner:any , completeFun:any  ,  customData:any , isQueue:boolean)
    {
        this.url = url;
        this.data = data;
        this.method = method;
        this._completeHandler = completeFun;
        this.customData = customData;
        this.isQueue = isQueue;
        this.owner = owner;
    }
    public send()
    {
        console.log(this.data);
        
        //创建HttpRequest对象
        let http: Laya.HttpRequest = new Laya.HttpRequest();
        //设置超时时间
        http.http.timeout = 10000000;
        let sendData:any = {};
        sendData.method = this.method;
        sendData.data = this.data;

        let queryString = Object.keys(sendData)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(sendData[key]))
    .join('&');
        //发送了一个简单的请求
        http.send(this.url + "?" + queryString , null, "get", "json");
        //设置完成事件，添加回调方法
        http.once(Laya.Event.COMPLETE, this, this.completeHandler);
        //设置错误事件，添加回调方法        
        http.once(Laya.Event.ERROR, this, this.errorHandler);
        //设置进度事件，添加回调方法        
        http.on(Laya.Event.PROGRESS, this, this.processHandler);
    }
    private processHandler(data:any): void {
        console.log("processHandler");
        
    }

    private errorHandler(error:any): void {
        console.log("errorHandler");
        this._completeHandler.call(this.owner,false  , this.customData);
        if(this.isQueue)
        {
            HttpManager.httpCom();
        }
            
    }

    private completeHandler(data:any): void {
        console.log("completeHandler");
        this._completeHandler.call(this.owner,true , data.data , this.customData);
        if(this.isQueue)
        {
            HttpManager.httpCom();
        }
        
    }
}