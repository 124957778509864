import VideoItem from "../../data/VideoItem";
import DataManager from "../../DataManager";
import ADManager from "../../tools/ad/ADManager";
import { PageManager } from "../../tools/PageManager";
import { WindowBase } from "../../tools/WindowBase";
import VideoItemCom from "./VideoItemCom";
const { regClass ,property} = Laya;
@regClass('y7Uyhf50ThimRxmORMJEIw')
export default class VideoItemListPage extends WindowBase
{
    @property({type:"Button"})
    public closeButton:Laya.Button;
    @property({type:"Image"})
    public coverImg:Laya.Image;
    @property({type:"Text"})
    public contentTxt:Laya.Text;
    @property({type:"List"})
    public videoItemList:Laya.List;
    private currentData:any;


    @property({type:"Text"})
    public titleTxt:Laya.Text;


    private videoListData:Array<VideoItem>;


    onAwake(): void {
        
    }
    onStart(): void {
        Laya.loader.load(DataManager.cndHost + this.currentData.cover, Laya.Loader.IMAGE).then((value:any)=>{
            this.coverImg.source =value;
        });
        this.titleTxt.text = this.currentData.title;
        this.contentTxt.text = this.currentData.introduce;
        this.videoItemList.array = [];

        this.closeButton.on(Laya.Event.CLICK , this , this._closeClick);
        // 单元格渲染处理
        this.videoItemList.renderHandler = new Laya.Handler(this, this.updateItem);
        DataManager. fetchUrlContent(DataManager.cndHost +  this.currentData.videoItemPath).then(content => {
            console.log(content); // 这里是URL的内容
            
            this.videoListData = JSON.parse(content);
            this.videoListData = this.videoListData.sort((a , b)=>{return a.index-b.index})
            this.videoItemList.array = this.videoListData;

        });

        ADManager.showAD();


    }

    _closeClick(e:any)
    {
        ADManager.removeAD();
        PageManager.closeWindow(this.owner);
    }


    private updateItem(cell: Laya.Sprite, index: number): void {
        
        console.log((cell as any).dataSource);
        cell.getComponent(VideoItemCom).setData((cell as any).dataSource);
    }
    public initData(data:any)
    {
        this.currentData = data;
    }
}