import DictClass from "../data/DictClass";
import VideoSet from "../data/VideoSet";
import DataManager from "../DataManager";
import EventManager from "../tools/event/EventManager";
import { HttpManager } from "../tools/http/HttpManager";
import { PageManager } from "../tools/PageManager";

import ClassItem from "./ClassItem";
import VideoSetItem from "./VideoSetItem";
import VideoItem from "./VideoSetItem";

const { regClass ,property} = Laya;
@regClass('2poY4xiCSmCYr-V-5Jxjvw')
export default class MainPanel extends Laya.Script
{
    @property({type:"List"})
    public tabList:Laya.List;
    @property({type:"List"})
    public videoList:Laya.List;
    

    @property({type:"TextInput"})
    public searchTxt:Laya.TextInput;
    @property({type:"Button"})
    public searchBtn:Laya.Button;

    public  inx:string;

    onAwake(): void {
        EventManager.init();
        HttpManager.init();
       
    }
    onStart(): void {
        EventManager.on(this , "configDataUpdate" , this._configUpdate);
        EventManager.on(this , "classClick" , this._clickClass);
        DataManager.updateData();
        this.tabList.renderHandler = new Laya.Handler(this, this.updateItem);
        this.videoList.renderHandler = new Laya.Handler(this, this.updateVideoSetItem);
        this.videoList.array=[];
        this.searchTxt.text = "";
        this.tabList.array = [];
        this.searchBtn.on(Laya.Event.CLICK , this , this._searchClick);
        // new VideoPlayer().createVideo("https://s3.ap-northeast-1.wasabisys.com/321video/video/326dac60-e2cd-4e3a-8b31-76ba0ab5b783/index.m3u8");

    }

    _searchClick(e:any)
    {
        this.showList(-1);
    }


    private showList(id:any)
    {
        let videoSets:Array<VideoSet> = new Array<VideoSet>();


        if(this.searchTxt.text != "")
        {
            for(let i = 0 ; i < DataManager.videoSet.length ; i ++)
            {
                if(DataManager.videoSet[i].isShow && DataManager.videoSet[i].title.indexOf(this.searchTxt.text) > -1)
                {
                    videoSets.push(DataManager.videoSet[i]);
                }
            }
        }

        else if(id == -1)
            {
                for(let i = 0 ; i < DataManager.videoSet.length ; i ++)
                    {
                        if(DataManager.videoSet[i].isHost && DataManager.videoSet[i].isShow)
                        {
                            videoSets.push(DataManager.videoSet[i]);
                        }
                    }
            }
        
        else
        {
            for(let i = 0 ; i < DataManager.videoSet.length ; i ++)
            {
                if(DataManager.videoSet[i].dictClassId == id && DataManager.videoSet[i].isShow )
                {
                    videoSets.push(DataManager.videoSet[i]);
                }
            }
        }


        
        this.videoList.array = videoSets;
    }


    private updateItem(cell: Laya.Sprite, index: number): void {
        
        console.log((cell as any).dataSource);
        cell.getComponent(ClassItem).setData((cell as any).dataSource);
    }
    private updateVideoSetItem(cell: Laya.Sprite, index: number): void {
        
        console.log((cell as any).dataSource);
        cell.getComponent(VideoSetItem).setData((cell as any).dataSource);
    }


    _configUpdate(e:any)
    {
        let classDatas:Array<DictClass> = new Array<DictClass>();
        let _dictC:DictClass = new DictClass();
        _dictC.id = -1;
        _dictC.index = -1;
        _dictC.name = "推荐";
        classDatas.push(_dictC);



        classDatas = classDatas.concat(DataManager.dictClass);
        this.tabList.array = classDatas;
    }
    _clickClass(e:any)
    {
        this.searchTxt.text = "";
        this.showList(e.id);
    }

}